import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import qs from "query-string"

import Gallery from "../components/gallery"
import SEO from "../components/seo"
import { Box, CircularProgress } from "@material-ui/core"
import { toGalleryNode } from "../utils"

const SearchPage = () => {
  const location = useLocation()
  const queryParams = qs.parse(location.search)
  const query = queryParams?.query
  const from = queryParams?.from
  const to = queryParams?.to
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const apiUrl = `${process.env.GATSBY_API_URL!}/search${location.search}`
    const fetchInit = async () => {
      setIsLoading(true)
      const result = await fetch(apiUrl)
      const items = await result.json()
      setFiles(items.map(toGalleryNode))
      setIsLoading(false)
    }
    fetchInit()
  }, [location])

  const formatDate = (date: string) => {
    return new Date(parseInt(date, 10)).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
  }

  const renderLoading = () => (
    <Box
      display="flex"
      p="6"
      justifyContent="center"
      alignItems="center"
      minHeight={200}
    >
      <CircularProgress />
    </Box>
  )

  const renderGallery = () => (
    <Gallery
      mediaItems={files}
      title="Search"
      subtitle=""
      body={
        <>
          Search critiria: <strong>{query}</strong>, between{" "}
          <strong>{formatDate(from as string)}</strong> till{" "}
          <strong>{formatDate(to as string)}</strong>
        </>
      }
    />
  )

  return (
    <>
      <SEO title="Gallery" />
      {isLoading ? renderLoading() : renderGallery()}
    </>
  )
}

export default SearchPage
